import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { User } from '../../../types/user';
import { TLD } from '../../../types/tld';
import Feedback from '../../Feedback/Feedback';
import { TimeZone, timeZoneList } from '../../../util/timeZoneConverter';
import { updateUserTimezone } from '../../../api/user';
import { statusFromResponseCode } from '../../../util/feedback';

interface UpdateUserTimezoneProps {
  env: string;
  user: User | undefined;
}

const UpdateUserTimezone = ({ env, user }: UpdateUserTimezoneProps) => {
  const [newTimeZone, setNewTimeZone] = useState('');
  const [status, setStatus] = useState({ state: '', message: '' });
  const tzs = timeZoneList();
  const [showAlert, setShowAlert] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading' });

    if (user) {
      if (!user.id || !newTimeZone){
        setShowAlert(true);
        return setStatus({
          state: 'error',
          message: 'Select a timezone',
        });
      }
      
      var successMessage = `Updated timezone for ${user.givenName} in ${env} to ${newTimeZone}.`;
      var failMessage = `Unable to update timezone for ${user.givenName} in ${env}.`

      if (user.id && newTimeZone) {
        updateUserTimezone(env as keyof TLD, user.id, newTimeZone)
          .then((res) => {
            setStatus({
              state: res.state,
              message: res.message + ' ' + successMessage,
            });
          })
          .catch((res) => {
            setStatus({
              state: res.state,
              message: res.message + ' ' + failMessage,
            });
          });
      }
    }
  }

  return (
    <Card body className='border-light'>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId='newTimezone'>
          <Row>
            <Col>
              <Form.Label>Select User's New Timezone</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                as='select'
                data-testid='tzdropdown'
                onChange={(e) => setNewTimeZone(e.target.value)}
                value={newTimeZone}
              >
                <option>Select Timezone</option>
                {tzs.map((tz: TimeZone) => (
                  <option key={tz.name}> {tz.name} </option>
                ))}
              </Form.Control>
            </Col>
            <Col>
              <Button type='submit' data-testid='tzUpdate'>
                Set Timezone
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
      <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
    </Card>
  );
};

export default UpdateUserTimezone;
