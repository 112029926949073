import React, { ReactElement } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import caret from '../../assets/caret.svg';
const faker = require('faker');

interface ActionWrapperProps {
  description: string;
  title: string;
  children: ReactElement;
}
export default function ActionWrapper({
  description,
  title,
  children,
}: ActionWrapperProps) {
  const eventKey = faker.datatype.string();
  return (
    <>
      <Accordion.Toggle
        as={Card.Header}
        variant='link'
        eventKey={eventKey}
        style={{ backgroundColor: 'white' }}
      >
        <b>{title}</b>
        <br />
        {description}
        <img
          src={caret}
          alt='expand'
          width='15'
          height='9'
          style={{ float: 'right' }}
        />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey} as={Card.Body}>
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </>
  );
}
