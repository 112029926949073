import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { User } from '../../../types/user';
import { generateActOrCatCard } from '../../../api/test-hooks';
import Feedback from '../../Feedback/Feedback';

//this may need additional updates to ensure that the user selected actually should see this option
//how ever I don't know if the test hook will generate this card for any user regardless of whether their med plan meets the critera
//according to the SRS, in order to qualify this card a user must meet the following criteria:
//    - have a rescue medication
//    - have no sensor associated with that rescue med
//    - have a controller medication
//    - that controller medication does have a sensor associated with it
//    - that controller sensor has achieved a first sync with the app

interface ACTorCATGeneratorProps {
  env: string;
  user: User | undefined;
  condition: string;
}

const ACTorCATCardGenerator = ({
  env,
  user,
  condition,
}: ACTorCATGeneratorProps) => {
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  const actInfo = { shortName: 'ACT', name: 'Asthma Control Test' };
  const catInfo = { shortName: 'CAT', name: 'COPD Assessment Test' };

  function generateActOrCat(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading ' });

    if (user?.id && user?.disease) {
      let testInfo = user.disease === 'asthma' ? actInfo : catInfo;
      var successMessage = `Generated ${testInfo.shortName} card for ${user.givenName} ${user.familyName} in ${env}.`;

      generateActOrCatCard({
        env,
        uid: user.id,
        quiz: testInfo.shortName.toLowerCase(),
      })
        .then((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
        })
        .catch(() => {
          setStatus({ state: 'error', message: 'Something went wrong' });
        });
      setShowAlert(true);
    } else {
      return setStatus({ state: 'error', message: 'Please enter a UID' });
    }
  }

  return (
    <tr>
      <td>
        Generates a brief {condition === 'asthma' ? actInfo.name : catInfo.name}{' '}
        questionnaire to help {user?.disease} patients monitor their disease
        management over time and sends a push notification letting the patient
        know that the quiz has appeared in their card feed.
        <br />
        <br />
        <Form onSubmit={generateActOrCat}>
          {user?.disease === condition ? (
            ''
          ) : (
            <p>
              <b>
                This patient doesn't fulfill the requirements for this card.
                This card is only available to {condition} patients.
              </b>
            </p>
          )}
          <Button
            type='submit'
            disabled={!(user?.disease === condition)}
            id='ACTCATcard'
          >
            {' '}
            Generate{' '}
            {condition === 'asthma'
              ? actInfo.shortName
              : catInfo.shortName}{' '}
            Card
          </Button>
        </Form>
        <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      </td>
    </tr>
  );
};

export default ACTorCATCardGenerator;
