import React, { useState } from 'react';
import { User } from '../../types/user';
import { CronInfo } from '../../types/cronInfo';
import { cronJob } from '../../api/test-hooks';
import Feedback from '../Feedback/Feedback';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  isAsthmaPatient,
  userHasSensorlessRescue,
} from '../../util/ComponentEligibiltyUtils';
import { countPatientSensors } from '../../util/sensors';
import { cronJobs } from '../../lib/constants';
import {
  ActOrCatDescription,
  RescueInputScheduleDescription,
  RescueInputScheduleUpdateDescription,
  QuietSensorDescription,
} from './CronDescriptions';

interface CronFormProps {
  env: string;
  user: User | undefined;
  cronInfo: CronInfo;
}

const determineUserEligibilityForCron = ({
  cron,
  user,
}: {
  cron: string;
  user: User;
}) => {
  switch (cron) {
    case cronJobs.QUIET_SENSOR.apiName:
      return countPatientSensors(user?.plan) > 0;
    case cronJobs.RESCUE_INPUT_SCHEDULE_CARD.apiName:
    case cronJobs.RESCUE_INPUT_SCHEDULE_CARD_UPDATE.apiName:
      return isAsthmaPatient(user) && userHasSensorlessRescue(user);
    default:
      return true;
  }
};

const getCronDescription = ({ cron, user }: { cron: string; user: User }) => {
  switch (cron) {
    case cronJobs.ACT_CARD.apiName:
    case cronJobs.CAT_CARD.apiName:
      return ActOrCatDescription({ user });
    case cronJobs.RESCUE_INPUT_SCHEDULE_CARD.apiName:
      return RescueInputScheduleDescription();
    case cronJobs.RESCUE_INPUT_SCHEDULE_CARD_UPDATE.apiName:
      return RescueInputScheduleUpdateDescription();
    case cronJobs.QUIET_SENSOR.apiName:
      return QuietSensorDescription();
    default:
      return (
        <>
          <p>Cron description coming soon!</p>
        </>
      );
  }
};

const CronForm = ({ env, user, cronInfo }: CronFormProps) => {
  const cronsWithDateFields = [
    cronJobs.RESCUE_INPUT_SCHEDULE_CARD.apiName,
    cronJobs.RESCUE_INPUT_SCHEDULE_CARD_UPDATE.apiName,
  ];
  const [status, setStatus] = useState({ state: '', message: '' });
  const [date, setDate] = useState('');
  const [time, setTime] = useState('17:00');
  const [showAlert, setShowAlert] = useState(false);
  const userIsEligible = user
    ? determineUserEligibilityForCron({ cron: cronInfo.apiName, user })
    : false;

  function handleSubmit(e: React.FormEvent<HTMLFormElement>, cron: CronInfo) {
    e.preventDefault();

    if (user) {
      let runDate;

      if (date && time) {
        let isoDate = new Date(date).toISOString();
        runDate = `${isoDate.split('T').shift()}T${time}:00.000Z`;
      }
      var successMessage = `Ran ${cron.uiName} job in ${env} for ${user.givenName} ${user.familyName}`;
      var failMessage = `Error running ${cron.uiName} in ${env} for ${user.givenName} ${user.familyName}`;

      cronJob({ env, uid: user.id, cronName: cron.apiName, date: runDate })
        .then((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
        })
        .catch(() => {
          setStatus({
            state: 'error',
            message: failMessage,
          });
        });
      setShowAlert(true);
    }
  }

  return (
    <Card body className='border-light'>
      <Form onSubmit={(e) => handleSubmit(e, cronInfo)}>
        <Form.Group controlId={cronInfo.apiName}>
          <Form.Text>
            {user ? getCronDescription({ cron: cronInfo.apiName, user }) : null}
          </Form.Text>
        </Form.Group>
        {!userIsEligible ? (
          <p>
            <b>This patient doesn't fulfill the requirements for this cron.</b>
          </p>
        ) : null}
        {cronsWithDateFields.includes(cronInfo.apiName) ? (
          <Form.Group controlId='dateTime'>
            <Form.Label>Set the cron's run date/time:</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  name={`${cronInfo.apiName}Date`}
                  placeholder='mm/dd/yyyy'
                  onChange={(e) => setDate(e.target.value)}
                  type='date'
                  value={date}
                />
              </Col>
              <Col>
                <Form.Control
                  name={`${cronInfo.apiName}Time`}
                  onChange={(e) => setTime(e.target.value)}
                  type='time'
                  value={time}
                />
              </Col>
            </Row>
            <br />
          </Form.Group>
        ) : null}
        <Button type='submit' disabled={!userIsEligible}>
          Run {cronInfo.uiName} Cron
        </Button>
      </Form>
      <br />
      <Feedback
        status={status}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
    </Card>
  );
};

export default CronForm;
