import Keycloak from 'keycloak-js';
import { TLD } from '../types/tld';

const redirectUri =
  window.location.hostname.split('.').pop() === 'localhost'
    ? 'http://localhost:3000'
    : `https://positronic.propellerhealth.biz`;

export function getTLD<K extends keyof TLD>(env: K) {
  const urlTld = {
    dev: 'biz',
    test: 'io',
    demo: 'onl',
    sndbx: 'org',
  };
  return urlTld[env];
}

let kc: any;

const keycloakAuth = {
  init: (env: string) => {
    const config = {
      realm: 'ph',
      url: `https://login.propellerhealth.${getTLD(env as keyof TLD)}/auth`,
      clientId: 'positronic',
    };
    kc = new (Keycloak as any)(config);
    return kc.init({
      checkLoginIframe: false,
      enableLogging: true,
      promiseType: 'native',
      redirectUri,
    });
  },
  login: () => {
    return kc.login();
  },
  logout: () => {
    sessionStorage.clear();
    return kc.logout();
  },

  clearToken: () => {
    kc.clearToken();
  },

  getToken: () => kc.token,
};

export default keycloakAuth;
