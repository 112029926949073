import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

export default function FAQs() {
  return (
    <ListGroup variant='flush'>
      <ListGroup.Item>
        <h5>
          How do I change the environment of the Propeller app on my phone?
        </h5>
        <p>
          If you are using the TestFlight version of the app, tap the Propeller
          logo at the top of the sign in screen to bring up the server selection
          screen.
        </p>
        <p>
          If you are using any other version, enter <code>seturl@asthma12</code>{' '}
          in the email field on the login screen and click "Sign in" (no
          password needed). Then select the environment you want from the
          "Current Settings" menu.
        </p>
        <p>
          For more info, see the{' '}
          <a
            href='https://discourse.propellerhealth.com/t/how-do-i-change-the-server-the-app-uses/82'
            target='_blank'
            rel='noopener noreferrer'
          >
            Discourse
          </a>{' '}
          article
        </p>
      </ListGroup.Item>
      <ListGroup.Item>
        <h5>How do I learn more about Positronic?</h5>
        <p>
          Please see the{' '}
          <a
            href='https://discourse.propellerhealth.com/t/positronic-who-0-a-guide-to-using-positronic-2-0/1238'
            target='_blank'
            rel='noopener noreferrer'
          >
            Discourse
          </a>{' '}
          article.
        </p>
      </ListGroup.Item>
    </ListGroup>
  );
}
