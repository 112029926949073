import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Feedback from '../../Feedback/Feedback';
import { User } from '../../../types/user';
import {
  generateCardFromTemplateName,
  GenerateCardTemplateName,
} from '../../../api/test-hooks';
import { isNonClicicalUser } from '../../../util/ComponentEligibiltyUtils';

interface CardTemplateNameCardProps {
  env: string;
  user: User | undefined;
  templateName: GenerateCardTemplateName;
  description?: string;
  actionText: string;
}

const CardTemplateNameCardGenerator = ({
  env,
  user,
  templateName,
  description,
  actionText,
}: CardTemplateNameCardProps) => {
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading ' });

    if (user?.id) {
      var successMessage = `Generated ${templateName} Card for ${user.givenName} ${user.familyName} in ${env}`;
      generateCardFromTemplateName({ env, uid: user.id, templateName })
        .then((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
        })
        .catch(() => {
          setStatus({ state: 'error', message: 'Something went wrong' });
        });
      setShowAlert(true);
    }
  }

  return (
    <tr>
      <td>
        {description ||
          `Generates a card with templateName=${templateName} in this users feed`}
        <br />
        <br />
        <Form onSubmit={handleSubmit}>
          {isNonClicicalUser(user) ? (
            ''
          ) : (
            <p>
              This user doesn't fulfill the requirements for this card. This
              card is not available to providers.{' '}
            </p>
          )}
          <Button
            type='submit'
            disabled={!isNonClicicalUser(user)}
            id={`${templateName}Card`}
          >
            {actionText}
          </Button>
        </Form>
        <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      </td>
    </tr>
  );
};

export default CardTemplateNameCardGenerator;
