import { Medication, MedicationInfo } from '../types/plan';

export function removePlanMedsFromGroupMeds(
  groupMeds: MedicationInfo[],
  planMeds?: Medication[]
) {
  if (!planMeds) {
    return groupMeds;
  }
  return groupMeds.filter((groupMed) => {
    return (
      planMeds?.findIndex((planMed) => {
        return planMed.medication.id === groupMed.id;
      }) === -1
    );
  });
}

export function getMed(id: string, meds: MedicationInfo[]) {
  if (!meds || meds.length === 0 || !id) {
    return null;
  }
  return meds.filter((med: MedicationInfo) => med.id === id);
}
