import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import AgeAccount from '../AgeAccount/AgeAccount';
import AddMedication from '../AddMedication/AddMedication';
import { User } from '../../../types/user';
import ActionWrapper from '../../ActionWrapper/ActionWrapper';
import SendAppHeartbeat from '../SendAppHeartbeat/SendAppHeartbeat';
import UpdateUserTimezone from '../UpdateUserTimezone/UpdateUserTimezone';

interface UserToolsProps {
  env: string;
  user?: User | undefined;
}

export default function UserTools({ env, user }: UserToolsProps) {
  return (
    <Card body className='border-light'>
      <h3>User Tools</h3>
      <p>
        <em>
          A collection of tools designed to help you put sample patients &
          caregivers in specific states
        </em>
      </p>
      <Accordion>
        <ActionWrapper
          title='Add a medication'
          description={`Adds a medication to ${user?.givenName} ${user?.familyName}'s plan`}
        >
          <AddMedication env={env} user={user} />
        </ActionWrapper>
        <ActionWrapper
          title='Age account'
          description={`Resets ${user?.givenName} ${user?.familyName}'s account creation date to a specific date/time`}
        >
          <AgeAccount env={env} user={user} />
        </ActionWrapper>
        <ActionWrapper
          title='Send app heartbeat'
          description={`Sends the Propeller server an app heartbeat for ${user?.givenName} ${user?.familyName} at a
        specified time/date`}
        >
          <SendAppHeartbeat env={env} user={user} />
        </ActionWrapper>
        <ActionWrapper
          title='Set user timezone'
          description={`Sets ${user?.givenName} ${user?.familyName}'s timezone to a specified region`}
        >
          <UpdateUserTimezone env={env} user={user} />
        </ActionWrapper>
      </Accordion>
    </Card>
  );
}
