import { Sensor } from '../types/plan';
import { request3x, getApiUrl, getApi4Headers, request4x } from '../util/request';
import { TLD } from '../types/tld';
import { generateSensorRawData } from '../util/sensors';


function generateRandomId(){
  var randomInt = Math.floor(Math.random() * 1000000000000000000).toString();
  return randomInt;
}

//more general version ove the above. Can be used to send a sensor heartbeat, a sensor usage, or a reset
//message type can be "heartbeat", "reset", or "usage"
export function sendSensorMessage(
  env: string,
  dateC: string,
  sensorToUse: Sensor,
  messageType: string
) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/sensor/message`;
  const date = dateC;
  const manuf = 'propeller';
  const vers = '1.0.0'; //idk if this should be something else, but its gonna be 1.0.0 for now
  const appVer = '9.9.9';
  const iden = "Propeller123";
  const transmitmodel = "Positronic-React";
  const pltfrm = 'ios';
  const pltfrmVersion = '9.1.0';
  var sensorModel: String;
  var eventID = generateRandomId();
  //since this endpoint only works if it gets "jellyfish", convert the two jellyfish types to just "jellyfish"
  if (
    sensorToUse.model === 'jellyfish92' ||
    sensorToUse.model === 'jellyfish87'
  ) {
    sensorModel = 'jellyfish';
  } else {
    sensorModel = sensorToUse.model;
  }

  return request3x('POST', url, {
    data: [
      {
        customData: {
          extendedStatus: "d1EBAAB8AqM=",
          rawData: generateSensorRawData(sensorToUse.mac, messageType),
        },
        date: date,
        dateReceived: date,
        id: eventID,
        sensor: {
          mac: sensorToUse.mac,
          manufacturer: manuf,
          model: sensorModel,
          version: vers,
        },
        type: messageType,
      },
    ],
    transmitDevice: {
      appVersion: appVer,
      id: iden,
      model: transmitmodel,
      platform: pltfrm,
      platformVersion: pltfrmVersion,
    },
  });
}

// API 4

export function addSensorToPlan({
  userId,
  medId,
  mac,
  env,
}: {
  userId: string;
  medId: string;
  mac: string;
  env: string;
}) {
  const apiURL = getApiUrl(env as keyof TLD);
  return fetch(`${apiURL}/users/${userId}/plan/medication/${medId}/sensor`, {
    method: 'POST',
    headers: getApi4Headers(),
    credentials: 'include',
    body: JSON.stringify({ mac }),
  })
    .then((res) => {
      if (res.ok) {
        return { success: true };
      }
      return res.json();
    })
    .then((res) => {
      if (res.elements) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
}

export function addSensorToPlan2({
  userId,
  medId,
  mac,
  env,
}: {
  userId: string;
  medId: string;
  mac: string;
  env: string;
}) {
  const apiURL = getApiUrl(env as keyof TLD);
  var requestURL = `${apiURL}/users/${userId}/plan/medication/${medId}/sensor`

  return request4x('POST', requestURL, {mac} ).then((res) => {
    return res;
  });

}