import { Status } from '../../src/components/Feedback/Feedback';

export function statusFromResponseCode(
  res: number,
  status: Status,
  successMessage?: string,
  failMessage?: string
) {
  //200s = success
  if (200 <= res && res < 300) {
    status.state = 'success';
    if (!successMessage) {
      successMessage = '';
    }
    status.message = `${res} returned. ` + successMessage;
  }
  //400 = permission or access issue
  else if (400 <= res && res < 500) {
    status.state = 'danger';
    if (!failMessage) {
      failMessage = '';
    }
    status.message =
      `${res} returned. Verify you have the proper permissions to perform this action. ` +
      failMessage;
  }
  //500s = server issue
  else if (res >= 500) {
    if (!failMessage) {
      failMessage = '';
    }
    status.state = 'danger';
    status.message = `${res} returned. Server Error. ${failMessage}`;
  }
  //everything else is also an error but we don't know wtf happened.
  else {
    if (!failMessage) {
      failMessage = '';
    }
    status.state = 'warning';
    status.message = `${res} returned. Something has gone wrong. ${failMessage}`;
  }
  return status;
}
