import React, { FormEvent, useEffect, useState } from 'react';

import { User } from '../../../types/user';
import { getGroupMeds } from '../../../api/group';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Feedback from '../../Feedback/Feedback';
import { addMedToPlan, addMedToPlan2 } from '../../../api/user';
import UsageItem from './Usage';
import { addSensorToPlan, addSensorToPlan2 } from '../../../api/sensor';
import { MedicationInfo, Usage } from '../../../types/plan';
import { getMed, removePlanMedsFromGroupMeds } from '../../../util/medications';
import { generateSensorMac } from '../../../util/sensors';
import { statusFromResponseCode } from '../../../util/feedback';

interface addMedProps {
  env: string;
  user: User | undefined;
}

export default function AddMedication({ env = 'dev', user }: addMedProps) {
  const [meds, setMeds] = useState<MedicationInfo[]>([]);
  const [medId, setMedId] = useState<string | undefined>(undefined);
  const [disabled, setDisabled] = useState(true);
  const [status, setStatus] = useState({ state: '', message: '' });
  const [usages, setUsages] = useState<Usage[]>([]);
  const [hasSensors, setHasSensors] = useState(false);
  const [addSensor, setAddSensor] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const groupId: string | undefined = user?.group?.id;

  useEffect(() => {
    if (groupId) {
      getGroupMeds(groupId, env)
        .then((res) => {
          const filteredMeds = removePlanMedsFromGroupMeds(
            res.medications,
            user?.plan?.medications
          );

          if (filteredMeds.length > 0) {
            setMeds(filteredMeds);
            setDisabled(false);
          }
        })
        .catch((err) =>
          setStatus({
            state: 'error',
            message: err?.elements
              ?.map((e: { id: string; field: string }) => e.id)
              .join('\n'),
          })
        );
    }
  }, [env, groupId]);

  function clearMed() {
    setUsages([]);
    setMedId(undefined);
  }

  interface MedData {
    medicationId: string;
    usageList?: Usage[] | [];
  }

  function submitMed(med: MedicationInfo) {
    setStatus({ state: 'loading', message: 'loading' });

    let medData: MedData = { medicationId: med.id };
    if (med.type === 'controller') {
      medData.usageList = usages;
    }

    //to do add a check for whether there is a medication selected and tell user to select one if none are

    if (user) {

      var successMessage =`Added ${med.name} to ${user.givenName}'s plan.`;
      addMedToPlan2(env, user.id, medData)
        .then((res) => {
          setDisabled(false);
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
          setMeds(meds.filter((m) => m.id !== medData.medicationId));

          if (hasSensors && addSensor) {
            const mac = generateSensorMac();
            successMessage = `Added ${med.name} and ${mac} to ${user.givenName}'s plan`;
            addSensorToPlan2({
              userId: user.id,
              medId: med.id,
              mac,
              env,
            }).then((res) => {
              setStatus({
                state: res.state,
                message: res.message + ' ' + successMessage,
              });
            });
            setHasSensors(false);
            setAddSensor(false);
          }
          clearMed();
        })
        .catch((err) => {
          setStatus({
            state: 'error',
            message: err?.elements
              ?.map((e: { id: string; field: string }) => e.id)
              .join('\n'),
          });
          clearMed();
          setDisabled(false);
        });

        setShowAlert(true);

    }
  }

  function handleUsageChange({
    index,
    unitDoses,
    hour,
    minute,
  }: {
    index: number;
    unitDoses: number | string;
    hour: number | string;
    minute: number | string;
  }) {
    const newUsage = {
      unitDoses,
      hour,
      minute,
    };
    usages[index] = newUsage;
    setUsages(usages);
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (!medId) return;
    setDisabled(true);
    const med = getMed(medId, meds);
    if (med && med[0]) {
      submitMed(med[0]);
    }
  }

  // TODO: add an "as-needed" option to conroller meds?
  function handleChange(medId: string) {
    // clear pevious selection, if any
    setHasSensors(false);
    setUsages([]);

    // set new selection
    setMedId(medId);
    let med = getMed(medId, meds);

    // determine if selected med is sensorable
    if (med && med[0]?.sensors) {
      setHasSensors(true);
    }

    // determine if selected med is controller
    if (med && med[0]?.type === 'controller') {
      setUsages([
        { unitDoses: '1', hour: '08', minute: '00' },
        { unitDoses: '1', hour: '20', minute: '00' },
      ]);
    }
  }

  return (
    <Card body className='border-light'>
      <Form>
        <Form.Group controlId='medication'>
          <Form.Label>
            {`Select a medication from the ${user?.group.displayName} list:`}
            &nbsp;
          </Form.Label>
          <Form.Control
            as='select'
            onChange={(e) => handleChange(e.target.value)}
            value={medId}
          >
            <option value='' data-testid=''></option>
            {meds?.map((med) => (
              <option key={med.id} value={med.id} data-testid={med.id}>
                {med.name}
              </option>
            ))}
          </Form.Control>
          <Form.Text>
            If a medication is already on {user?.givenName}'s plan, it does not
            appear on this list.
          </Form.Text>
        </Form.Group>

        {hasSensors ? (
          <Form.Group controlId='addSensor'>
            <Form.Check
              checked={addSensor}
              className='textMed'
              inline
              label='Add a sensor to go with this med?'
              name='addSensor'
              onChange={(e) => setAddSensor(e.target.checked)}
            />
          </Form.Group>
        ) : null}

        {usages.map((usage, index) => (
          <UsageItem
            key={`${usage.hour}${usage.minute}`}
            index={index}
            unitDoses={usage.unitDoses}
            hour={usage.hour}
            minute={usage.minute}
            setUsages={handleUsageChange}
          />
        ))}

        <Button type='submit' onClick={handleSubmit} disabled={disabled}>
          Add med to plan
        </Button>
      </Form>
      <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
    </Card>
  );
}
