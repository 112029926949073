import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { User } from '../../../types/user';
import { TLD } from '../../../types/tld';
import Feedback from '../../Feedback/Feedback';
import { timeFormatter } from '../../../util/timeZoneConverter';
import { sendAppHeartbeat } from '../../../api/user';
import { statusFromResponseCode } from '../../../util/feedback';

interface SendAppHeartbeatProps {
  env: string;
  user: User | undefined;
}

const SendAppHeartbeat = ({ env, user }: SendAppHeartbeatProps) => {
  const [dateCreated, setDateCreated] = useState('');
  const [timeCreated, setTimeCreated] = useState('00:00');
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);


  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading' });

    if (user) {
      if (!user.id || !dateCreated || !timeCreated){
        setShowAlert(true);
        return setStatus({
          state: 'error',
          message: 'enter a date, and time',
        });
      }
      var successMessage = `Sent app heartbeat for ${user.givenName} ${user.familyName} in ${env} on ${dateCreated}.`;
      var failMessage =  `Unable to send app heartbeat for ${user.givenName} ${user.familyName} in ${env} on ${dateCreated}`
        
      sendAppHeartbeat(
        env as keyof TLD,
        user.id,
        timeFormatter(user.timeZone, dateCreated, timeCreated)
      )
        .then((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
        })
        .catch((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + failMessage,
          });
        });
    }
  }

  return (
    <Card body className='border-light'>
      <Form onSubmit={handleSubmit}>
        <p>
          <em>
            <b>Note:</b> Sends an app heartbeat (not a sensor heartbeat, this
            heartbeat is like refreshing the app) for the date and time
            selected.
          </em>
        </p>
        <Form.Group controlId='heatbeat'>
          <Row>
            <Col>
              <Form.Label>Select App Heartbeat Date and Time</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                type='date'
                name='heatbeatDate'
                data-testid='heatbeatDate'
                onChange={(e) => setDateCreated(e.target.value)}
                value={dateCreated}
              />
            </Col>
            <Col>
              <Form.Control
                type='time'
                name='heatbeatTime'
                data-testid='heatbeatTime'
                onChange={(e) => setTimeCreated(e.target.value)}
                value={timeCreated}
              />
            </Col>
            <Col>
              <Button type='submit' data-testid='heatbeatSend'>
                Send App Heartbeat
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
      <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
    </Card>
  );
};

export default SendAppHeartbeat;
