import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Feedback from '../../Feedback/Feedback';
import { User } from '../../../types/user';
import { generateSurveyMonkeyTestCard } from '../../../api/test-hooks';

interface SurveyMonkeyTestCardProps {
  env: string;
  user: User | undefined;
}

const SurveyMonkeyTestCardGenerator = ({
  env,
  user,
}: SurveyMonkeyTestCardProps) => {
  const [surveyId, setSurveyId] = useState('');
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading ' });

    if (user?.id) {
      var successMessage = `Generated SurveyMonkey Test Card pointing at ${surveyId} for ${user.givenName} ${user.familyName} in ${env}`;
      generateSurveyMonkeyTestCard({ env, uid: user.id, surveyId })
        .then((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
        })
        .catch(() => {
          setStatus({ state: 'error', message: 'Something went wrong' });
        });
      setShowAlert(true);
    }
  }

  return (
    <tr>
      <td>
        Generates a card in the user's feed that launches a SurveyMonkey survey
        when its call to action (CTA) button is tapped. The user's language and
        identity are passed to SurveyMonkey (via the anonymizing
        <a href='https://discourse.propellerhealth.com/t/surveymonkey-and-webware-for-platform-engineers/683'>
          `i` and `p`
        </a>
        parameters) so that it is easier to exercise the i18n and response
        collection features of the SurveyMonkey integration
        <br />
        <br />
        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3' controlId='formBasicSurveyId'>
            <Form.Label>Survey Id</Form.Label>
            <Form.Control
              required
              type='input'
              placeholder='Enter a SurveyMonkey surveyId'
              onChange={(e) => setSurveyId(e.target.value)}
              value={surveyId}
            />
            <Form.Text className='text-muted'>
              A `surveyId` is the slug on the end of a SurveyMonkey collector
              URL. e.g. the `surveyId` of the survey with collector URL
              `https://www.research.net/r/ZG5PYFH` is `ZG5PYFH`
            </Form.Text>
          </Form.Group>

          <Button type='submit' id='surveyMonkeyTestCard' disabled={!surveyId}>
            Generate SurveyMonkey Test Card
          </Button>
        </Form>
        <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      </td>
    </tr>
  );
};

export default SurveyMonkeyTestCardGenerator;
