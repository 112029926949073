function formatDOB(date: Date, year: number): string {
  const birthdate = new Date(date.setFullYear(year)).toISOString();
  return birthdate.split('T')[0];
}

export function createDOB(currentDate: Date, yearsOld: number): string {
  const year = currentDate.getFullYear() - yearsOld;
  return formatDOB(currentDate, year);
}

export function formatDate(date: string) {
  return new Date(date).toISOString().split('T')[0];
}

export function getOneMonthAgoAsString(){
  let today = new Date();
  let m = today.getMonth();
  today.setMonth(m-1);
  return today.toISOString().slice(0, 10);
}

export function getTodaysDate(){
  let today = new Date();
  return today.toISOString().slice(0, 10);
}
