import { Accordion, Button, Card } from 'react-bootstrap';
import { cronJobs } from '../../lib/constants';
import { User } from '../../types/user';
import ActionWrapper from '../ActionWrapper/ActionWrapper';
import React, { useState } from 'react';
import CronForm from './CronForm';
interface CronToolsProps {
  env: string;
  user: User | undefined;
}

const CronTools: React.FunctionComponent<CronToolsProps> = ({ env, user }) => {
  const [ description, setDescription ] = useState(false);

  return (
    <Card body className='userTools border-light'>
      <h3>Cron Tools</h3>
      <p>
        <em>A collection of tools to run cron jobs.</em>
      </p>
      <Accordion>
      <ActionWrapper
          title='Cron Information'
          description='What is a cron job?'
        >
          <div>
            <br />
            Crons are routine tasks that run on a schedule. Some examples:
            <ul>
              <li>Generating daily cards during Wellness Challenge weeks</li>
              <li>Scoring patients on a regular cadence</li>
              <li>Sending out the monthly SPP Security Reminder emails you know and love</li>
            </ul>
  
            Positronic's cron actions are primarily designed for QA testing purposes.
            <br/>
            If you ended up here because you are trying to generate a particular card, 
            we suggest trying the 'card actions' tab instead.
          </div>  
        </ActionWrapper>
        <ActionWrapper
          title={user?.disease === 'asthma' 
            ? cronJobs.ACT_CARD.uiName 
            : cronJobs.CAT_CARD.uiName}
          description={user?.disease==='asthma'
            ? 'Generates an ACT card for the currently logged-in patient'
            : 'Generates a CAT card for the currently logged-in patient'}
        >
          <CronForm 
            env={env} 
            user={user} 
            cronInfo={user?.disease === 'asthma' 
              ? cronJobs.ACT_CARD 
              : cronJobs.CAT_CARD} 
          />
        </ActionWrapper>
        <ActionWrapper
          title={cronJobs.QUIET_SENSOR.uiName}
          description='Sends a notification when a sensor has not synced in 48 hours'
        >
          <CronForm env={env} user={user} cronInfo={cronJobs.QUIET_SENSOR} />
        </ActionWrapper>
        <ActionWrapper
          title={cronJobs.RESCUE_INPUT_SCHEDULE_CARD.uiName}
          description='Generates a Rescue Input Schedule card for the currently logged-in patient'          
        >
          <CronForm env={env} user={user} cronInfo={cronJobs.RESCUE_INPUT_SCHEDULE_CARD} />
        </ActionWrapper>
        <ActionWrapper
          title={cronJobs.RESCUE_INPUT_SCHEDULE_CARD_UPDATE.uiName}
          description='Updates a Rescue Input Schedule card for the currently logged-in patient'          
        >
          <CronForm env={env} user={user} cronInfo={cronJobs.RESCUE_INPUT_SCHEDULE_CARD_UPDATE} />
        </ActionWrapper>
      </Accordion>
    </Card>
  );
};

export default CronTools;