import React, { useState } from 'react';
import { User } from '../../../types/user';
import { Medication, Sensor } from '../../../types/plan';
import { sendSensorMessage } from '../../../api/sensor';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { timeFormatter } from '../../../util/timeZoneConverter';
import Feedback from '../../Feedback/Feedback';
import { getTodaysDate } from '../../../util/dates';
import { statusFromResponseCode } from '../../../util/feedback';

interface SendSensorMessageProps {
  env: string;
  user: User | undefined;
}

const SendSensorMessage: React.FunctionComponent<SendSensorMessageProps> = ({
  env,
  user,
}) => {
  const [dateCreated, setDateCreated] = useState(`${getTodaysDate()}`);
  const [sensorMac, setSensorMac] = useState('');
  const [messageType, setMessageType] = useState('');
  const [timeCreated, setTimeCreated] = useState('12:00');
  const usageTypes = ['heartbeat', 'usage', 'reset'];
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);


  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading' });
    var useThisSensor = checkAllTheMedsforTheSensor(sensorMac);

    if (user) {
      if (
        !user.id ||
        !messageType ||
        !dateCreated ||
        !timeCreated ||
        !sensorMac
      ){
        setShowAlert(true);
        return setStatus({
          state: 'error',
          message: 'enter a UID, sensor type, date, and time',
        });
      }
        

      if (user.id && dateCreated && timeCreated && sensorMac && useThisSensor) {
        var successMessage = `Sent ${messageType} on ${sensorMac} for ${user.givenName} ${user.familyName} in ${env} on ${dateCreated}.`;
        sendSensorMessage(
          env,
          timeFormatter(user.timeZone, dateCreated, timeCreated),
          useThisSensor,
          messageType
        )
          .then((res) => {
            setStatus({
              state: res.state,
              message: res.message + ' ' + successMessage,
            });
          })
          .catch((err) => {
            setStatus({
              state: 'error',
              message: err?.elements
                ?.map((e: { id: string; field: string }) => e.id)
                .join('\n'),
            });
          });
          setShowAlert(true);
      }
    }
  }

  //given an array of sensors and a macaddress, it returns the sensor with that macaddress
  function getSensorfromMac(sensorList: Sensor[], sensorMac: string) {
    var sensor: Sensor | undefined;
    sensor = sensorList.find((sensor: Sensor) => sensor.mac === sensorMac);
    return sensor;
  }
  //searches the user's plan to find the sensor with the specified mac address
  function checkAllTheMedsforTheSensor(mac: string) {
    var mySensor: Sensor | undefined;
    //if there are medications on the user do some things
    if (user?.plan.medications) {
      for (
        let i = 0;
        user?.plan.medications.length > i && mySensor === undefined;
        i++
      ) {
        mySensor = getSensorfromMac(user.plan.medications[i].sensors, mac);
      }

      return mySensor;
    }
  }

  return (
    <Card body className='border-light'>
      <Form onSubmit={handleSubmit}>
        <h4>Send Sensor Event</h4>

        <em>
          Adds a usage, heartbeat, or reset for the selected sensor at the
          selected time. Uses API 3.x, POST api/sensor/message.
          <small>
            <ul>
              <li>
                <strong>usage:</strong> sends an event as if the user has take a
                puff of their medication. On a BLE this would be equivalent
                pressing the large top button.
              </li>
              <li>
                <strong>heartbeat:</strong> sends an event as if the sensor has
                connected to the app without the user taking any medication. On
                a BLE this is equivalent to pressing the small side button.
              </li>
              <li>
                <strong>reset:</strong> sends a reset event for the sensor. This
                would be eqvuivalent to pressing the small button in the reset
                sequence on the BLE.{' '}
              </li>
            </ul>
          </small>
        </em>

        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Form.Group controlId='sensorMac'>
              <Form.Label>Select Sensor</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setSensorMac(e.target.value)}
                value={sensorMac}
              >
                <option value="">Select sensor</option>
                {user?.plan?.medications?.map((med: Medication) => {
                  return med.sensors.map((sensor: Sensor) => (
                    <option key={sensor.mac}> {sensor.mac} </option>
                  ));
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Form.Group controlId='messageType'>
              <Form.Label>Select Event Type</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setMessageType(e.target.value)}
                value={messageType}
              >
                <option>Select Event Type</option>
                {usageTypes.map((type: string) => {
                  return <option key={type}> {type} </option>;
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Form.Group controlId='sensorMessageSendDate'>
              <Form.Label>Enter event date</Form.Label>
              <Form.Control
                type='date'
                name='sensorMessageSendDate'
                onChange={(e) => setDateCreated(e.target.value)}
                value={dateCreated}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='sensorMessageSendDate'>
              <Form.Label>Enter event time</Form.Label>
              <Form.Control
                type='time'
                name='sensorMessageSendTime'
                onChange={(e) => setTimeCreated(e.target.value)}
                value={timeCreated}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Button type='submit'>Send Sensor Event</Button>
          </Col>
        </Row>
      </Form>
      <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
    </Card>
  );
};

export default SendSensorMessage;
