import { TLD } from '../types/tld';
import { statusFromResponseCode } from './feedback';
import keycloakAuth from './keycloak';

export const DEV_URL = 'https://dev.propellerhealth.biz/api';
export const TEST_URL = 'https://test.propellerhealth.io/api';
export const SNDBX_URL = 'https://api.propellerhealth.org/api';
export const DEMO_URL = 'https://api.propellerhealth.onl/api';

export function getApiUrl(env: keyof TLD) {
  const url = {
    dev: DEV_URL,
    test: TEST_URL,
    sndbx: SNDBX_URL,
    demo: DEMO_URL,
  };
  return url[env];
}

export function getApi4Headers() {
  return {
    'x-ph-api-version': '4.47.0',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${keycloakAuth.getToken()}`,
  };
}

export function request(method: string, url = '', data = {}) {
  var status = { state: '', message: '' };

  return fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.status) // this is a failover until we get some better responses coming back from the API :very_sad:
    .then((res) => {
      status = statusFromResponseCode(res, status);
      return status;
    });
}

export function request3x(method: string, url = '', data = {}) {
  var status = { state: '', message: '' };

  return (
    fetch(url, {
      method: method,
      headers: {
        'x-ph-api-version': '3.92.0',
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.status)
      .then((res) => {
        status = statusFromResponseCode(res, status);
        console.log(status);
        return status;
      })
  );
}

export function request4x(method: string, url = '', data = {}) {
  var status = { state: '', message: '' };
  var headers4x = getApi4Headers();

  return (
    fetch(url, {
      method: method,
      headers: headers4x,
      credentials: 'include',
      body: JSON.stringify(data),
    })
      .then((res) => res.status)
      .then((res) => {
        status = statusFromResponseCode(res, status);
        console.log(status);
        return status;
      })
  );
}
