import { Medication } from '../types/plan';
import { User } from '../types/user';

const userTypes = [
  'admin',
  'caregiver',
  'control',
  'patient',
  'practicioner',
  'prospect',
];
const nonClinicalUserTypes = ['caregiver', 'control', 'patient'];
const clinicalUserTypes = ['admin', 'practicioner'];

//returns true if the provided user is a patient with asthma as their disease
export function isAsthmaPatient(user: User | undefined) {
  var isAsthmaPatient = false;
  if (user?.role === 'patient' && user?.disease === 'asthma') {
    isAsthmaPatient = true;
  }
  return isAsthmaPatient;
}

//is returns true if the user is a caregiver, patient, or control
export function isNonClicicalUser(user: User | undefined) {
  var isNonClicical = false;
  if (user?.role && nonClinicalUserTypes.includes(user?.role)) {
    isNonClicical = true;
  }
  return isNonClicical;
}

//returns true if the user has at least one rescue medication on thier plan
export function userHasRescueMed(user: User | undefined) {
  var rescueMed = user?.plan?.medications?.filter(
    (med: Medication) => med.medication.type === 'rescue'
  );
  console.log(rescueMed);
  return rescueMed ? rescueMed.length > 0 : false;
}

//returns true if the user has at least one rescue med without a sensor on their plan
export function userHasSensorlessRescue(user: User | undefined) {
  var listOfRescues = user?.plan?.medications?.filter(
    (med: Medication) =>
      med.medication.type === 'rescue' && med.sensors.length === 0
  );
  return listOfRescues ? listOfRescues.length > 0 : false;
}
