export const cronJobs = {
  ACT_CARD: {
    apiName: 'act_card',
    uiName: 'ACT Card',
  },
  ALERTS_DELETION: {
    apiName: 'alerts_deletion',
    uiName: 'Alerts Deletion',
  },
  CAT_CARD: {
    apiName: 'cat_card',
    uiName: 'CAT Card',
  },
  CONTROL_TRANSITION: {
    apiName: 'control_transition',
    uiName: 'Control Transition',
  },
  DST_SCHEDULE: {
    apiName: 'dst_schedule',
    uiName: 'DST Schedule',
  },
  EXPIRE_PERSISTENT_CARDS: {
    apiName: 'expire_persistent_cards',
    uiName: 'Expire Persisten Cards',
  },
  INTERVIEW_CARD: {
    apiName: 'interview_card',
    uiName: 'Interview Card',
  },
  KEYCLOAK_SYNC: {
    apiName: 'keycloak_sync',
    uiName: 'Keycloak Sync',
  },
  METRICS_WEBHOOK: {
    apiName: 'metrics_webhook',
    uiName: 'Metrics Webhook',
  },
  MISSED_DOSE_ALERT: {
    apiName: 'missed_dose_alerts',
    uiName: 'Missed Dose Alert',
  },
  PREDICTIVE_CARDS: {
    apiName: 'predictive_card',
    uiName: 'Predictive Card',
  },
  QUIET_SENSOR: {
    apiName: 'quiet_sensor',
    uiName: 'Quiet Sensor',
  },
  RECOVER_SENSORS: {
    apiName: 'recover_sensors',
    uiName: 'Recover Sensors',
  },
  RESCUE_INPUT_SCHEDULE_CARD: {
    apiName: 'rescue_input_schedule_card',
    uiName: 'Rescue Input Schedule Card',
  },
  RESCUE_INPUT_SCHEDULE_CARD_UPDATE: {
    apiName: 'rescue_input_schedule_card_update',
    uiName: 'Rescue Input Schedule Card Update',
  },
  USERDAYS: {
    apiName: 'userdays',
    uiName: 'Userdays',
  },
  WEEKLY_EMAIL: {
    apiName: 'weekly_email',
    uiName: 'Weekly Email',
  },
  WELLNESS_WEEK: {
    apiName: 'wellness_week',
    uiName: 'Wellness Week',
  },
};
