import React, { useState } from 'react';
import { User } from '../types/user';
import { ActionTabs } from '../components/ActionTabs/ActionTabs';
import UserInfo from '../components/UserInfo/UserInfo';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

interface HomeProps {
  user: User;
  env: string;
}
function Home({ user, env }: HomeProps) {
  const [activeUser, setActiveUser] = useState(user);

  return (
    <Container>
      {user ? (
        <Row>
          <Col xs={12} sm={4}>
            <UserInfo
              env={env}
              user={user}
              activeUser={activeUser}
              setActiveUser={setActiveUser}
            />
          </Col>
          <Col>
            <ActionTabs env={env} user={activeUser} />
          </Col>
        </Row>
      ) : (
        <div>Not logged in</div>
      )}
    </Container>
  );
}

export default Home;
