import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ageAccount } from '../../../api/test-hooks';
import { User } from '../../../types/user';
import Feedback from '../../Feedback/Feedback';
import { timeFormatter } from '../../../util/timeZoneConverter';
import { statusFromResponseCode } from '../../../util/feedback';

interface AgeAccountProps {
  env: string;
  user: User | undefined;
}

const AgeAccount = ({ env, user }: AgeAccountProps) => {
  const [dateCreated, setDateCreated] = useState('');
  const [timeCreated, setTimeCreated] = useState('');
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading' });

    if (user) {
      if (!user.id || !dateCreated || !timeCreated){
        setShowAlert(true);
        return setStatus({
          state: 'error',
          message: 'enter a UID, date, and time',
        });
      }
        

      if (user.id && dateCreated && timeCreated) {
        var successMessage = `Aged account for ${user.givenName} ${user.familyName} in ${env} to ${dateCreated}`;
        var failMessage = `Unable to age account to the date and time specified.`;
        ageAccount({
          env,
          uid: user.id,
          dateC: timeFormatter(user.timeZone, dateCreated, timeCreated),
        })
          .then((res) => {
            setStatus({
              state: res.state,
              message: res.message + ' ' + successMessage,
            });
          })
          .catch((res) => {
            setStatus({
              state: res.state,
              message: res.message + ' ' + failMessage,
            });
          });
          setShowAlert(true);
      }
    }
  }

  return (
    <Card body className='border-light'>
      <Form onSubmit={handleSubmit}>
        <p>
          <em>
            <b>Note:</b> Aging a user account will also age persisted cards and
            run the 'expire' job on all cards for the user.
          </em>
        </p>
        <Form.Group controlId='accountDateCreated'>
          <Row>
            <Col>
              <Form.Label>Enter new date created</Form.Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                type='date'
                name='accountDateCreated'
                onChange={(e) => setDateCreated(e.target.value)}
                value={dateCreated}
              />
            </Col>
            <Col>
              <Form.Control
                type='time'
                name='accountTimeCreated'
                onChange={(e) => setTimeCreated(e.target.value)}
                value={timeCreated}
              />
            </Col>
            <Col>
              <Button type='submit'>Age account</Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
      <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
    </Card>
  );
};

export default AgeAccount;
