import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { freeSensor } from '../../../api/test-hooks';
import { User } from '../../../types/user';
import { Medication, Sensor } from '../../../types/plan';
import Feedback from '../../Feedback/Feedback';
import { statusFromResponseCode } from '../../../util/feedback';

interface RemoveSensorProps {
  env: string;
  user: User | undefined;
}

const RemoveSensor = ({ env, user }: RemoveSensorProps) => {
  const [sensorMac, setSensorMac] = useState('');
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (user) {
      var successMessage = `Removed sensor ${sensorMac} from ${user.givenName} ${user.familyName}'s plan`;
      var failMessage = `Unable to remove sensor ${sensorMac} from ${user.givenName} ${user.familyName}'s plan`;
      if (!sensorMac) {
        setShowAlert(true);
        return setStatus({
          state: 'error',
          message: `Please select a sensor to remove from ${user.givenName} ${user.familyName}'s plan`,
        });
      } else {
        freeSensor({
          env,
          macAddress: sensorMac,
        })
          .then((res) => {
            setStatus({
              state: res.state,
              message: res.message + ' ' + successMessage,
            });
          })
          .catch((res) => {
            setStatus({
              state: res.state,
              message: res.message + ' ' + failMessage,
            });
          });
          setShowAlert(true);
      }
    }
  }

  return (
    <Card body className='border-light'>
      <Form onSubmit={handleSubmit}>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Form.Group controlId='sensorMac'>
              <Form.Label>Select a sensor to remove</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setSensorMac(e.target.value)}
                value={sensorMac}
              >
                <option value="">Select sensor</option>
                {user?.plan?.medications?.map((med: Medication) => {
                  return med.sensors.map((sensor: Sensor) => (
                    <option key={sensor.mac}> {sensor.mac} </option>
                  ));
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Button type='submit'>Remove Sensor</Button>
          </Col>
        </Row>
      </Form>
      <br />
      <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
    </Card>
  );
};

export default RemoveSensor;
