import { Plan } from '../types/plan';
import { crc16xmodem } from 'crc';

export const countPatientSensors = (plan: Plan) => {
  let sensorCount = 0;
  if (plan?.medications) {
    plan.medications.forEach((med) => {
      if (med.sensors) {
        sensorCount += med.sensors.length;
      }
    });
  }

  return sensorCount;
};

function generateLetter() {
  const alpha = 'ABCDEF0123456789';
  return alpha[Math.floor(Math.random() * alpha.length)];
}

function generateTwoLetterString() {
  return Array.from({ length: 2 })
    .map(() => generateLetter())
    .join('');
}

export function generateSensorMac() {
  return `F8:FE:5C:${generateTwoLetterString()}:${generateTwoLetterString()}:${generateTwoLetterString()}`;
}

export function generateSensorRawData(sensorMac: String, msgType: string) {
  let event_type = 0; // 1 = Usage, 2 = Heartbeat, 3 = Reset

  if (msgType === 'usage') {
    event_type = 1;
  }
  if (msgType === 'heartbeat') {
    event_type = 2;
  }

  if (msgType === 'reset') {
    event_type = 3;
  }

  let event_id = 109;
  let mac = sensorMac;
  let elapsed_time = 6; //update later to allow this to be user input
  let battery = 177; //update later to allow this to be user input
  let remaining_events = 0;
  let mode = 8;
  let firmware = '0106';
  let myRawData = getRawData(
    mac,
    event_type,
    event_id,
    elapsed_time,
    battery,
    remaining_events,
    mode,
    firmware
  );
  return myRawData;
}

export function getRawData(
  mac: String,
  eventType: number,
  eventId: number,
  elapsedTime: number,
  battery: number,
  remainingEvents: number,
  mode: number,
  firmware: string
): string {
  // wholeheartedly copied from npm-sensor but switched to use the ArrayBuffer and DataView.
  // looked into browserfy but we'd have to convert all npm packages in the npm-sensor tree to raw js
  let mac_clean = mac.replace(/:/g, '').toLowerCase();
  console.log(mac_clean);
  let offset = 0;
  let buffer = new ArrayBuffer(29);
  let dataView = new DataView(buffer);

  dataView.setUint32(offset, 0x011d0000, false); // padding
  offset += 4;
  dataView.setUint8(offset, eventType); // event_type
  offset++;
  dataView.setUint16(offset, parseInt(mac_clean.substr(8, 4), 16), true); //mac
  offset += 2;
  dataView.setUint16(offset, parseInt(mac_clean.substr(4, 4), 16), true); // mac
  offset += 2;
  dataView.setUint16(offset, parseInt(mac_clean.substr(0, 4), 16), true); // mac
  offset += 2;
  dataView.setUint32(offset, eventId, true); //eventid
  offset += 4;
  dataView.setUint32(offset, elapsedTime, true); // elapsed time
  offset += 4;
  dataView.setUint8(offset, battery); // battery
  offset++;
  dataView.setUint16(offset, remainingEvents, true); // remaining events
  offset += 2;
  dataView.setUint8(offset, mode); // sensor mode
  offset++;

  var firmware_hex = parseInt(firmware, 16); // sensor firmware version
  dataView.setUint16(offset, firmware_hex, true);
  offset += 2;

  var copy = Buffer.from(buffer.slice(0, 27));

  var crcResult = crc16xmodem(copy);
  dataView.setUint16(27, crcResult, true);
  offset += 2;

  //
  // base64 the array o' bytes
  //

  // view ArrayBuffer of bytes as array of 8 bit unsigned integers;
  let uintBuffer = new Uint8Array(buffer);
  // concatenate string value of each unsigned integer to a new string
  let reducerFunc = function (previousValue: string, currentValue: number) {
    return previousValue + String.fromCharCode(currentValue);
  };
  let toEncode: string = uintBuffer.reduce(reducerFunc, '');
  let rawData: string = btoa(toEncode);

  return rawData;
}
