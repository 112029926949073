import React, { Dispatch, SetStateAction, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

export interface Status {
  state: string; // "success" or "error"
  message: string;
}

interface FeedbackProps {
  status: Status;
  showAlert: boolean;
  setShowAlert: Dispatch<SetStateAction<any>>;
}

const Feedback = ({ status, showAlert, setShowAlert }: FeedbackProps) => {
  var variant = '';
  if (status.state == 'error') {
    variant = 'warning';
  } else {
    variant = status.state;
  }
  if (showAlert) {
    return (
      <div>
        <Alert
          variant={variant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          {status.message}
        </Alert>
      </div>
    );
  }
  return <div></div>;
};

export default Feedback;
