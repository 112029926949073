import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ActionWrapper from '../../ActionWrapper/ActionWrapper';
import Card from 'react-bootstrap/Card';
import AgeSensor from '../AgeSensor/AgeSensor';
import RemoveSensor from '../RemoveSensor/RemoveSensor';
import SendSensorMessage from '../SendSensorMessage/SendSensorMessage';
import { User } from '../../../types/user';
import { Medication } from '../../../types/plan';

interface SensorToolsProps {
  env: string;
  user: User | undefined;
}

const SensorTools: React.FunctionComponent<SensorToolsProps> = ({
  env,
  user,
}) => {
  let totalSensors = 0;
  if (!user?.plan) {
    totalSensors = 0;
  } else {
    user?.plan.medications?.forEach(
      (med: Medication) => (totalSensors += med.sensors.length)
    );
  }

  return (
    <Card body className='sensorTools border-light'>
      <h3>Sensor Tools</h3>
      <p>
        <em>
          A collection of tools designed to emulate sensor features and
          behaviors.
        </em>
      </p>
      {totalSensors === 0 ? (
        <p>No Sensors Available</p>
      ) : (
        <Accordion>
          <ActionWrapper
            title='Age Sensor'
            description='Sets the age of the sensor and first sensor sync to a specified time and date'
          >
            <Card body className='border-light'>
              <AgeSensor 
                env={env} 
                user={user} 
              />
            </Card>
          </ActionWrapper>
          <ActionWrapper 
            title='Send Sensor Event'
            description='Sends the Propeller server a hearbeat, usage, or reset for a given sensor'
          >
            <Card body className='border-light'>
              <SendSensorMessage
                env={env}
                user={user}
              />
            </Card>
          </ActionWrapper>
          <ActionWrapper
            title='Remove Sensor'
            description='Removes specified sensors from the logged-in user&#39;s plan'
          >
            <Card body className='border-light'>
              <RemoveSensor
                env={env}
                user={user} 
              />
            </Card>
          </ActionWrapper>
        </Accordion>
      )}
    </Card>
  );
};

export default SensorTools;
