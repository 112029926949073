import React, { Dispatch, SetStateAction, useState } from 'react';
import { Followed, User } from '../../types/user';
import { getUserById } from '../../api/user';
import { Form } from 'react-bootstrap';
import { TLD } from '../../types/tld';
const userRolesFollowing = ['caregiver', 'admin', 'physician'];

interface PatientManagerProps {
  user: User;
  env: string;
  activeUser: User;
  setActiveUser: Dispatch<SetStateAction<any>>;
}

interface availablePatient {
  givenName: string;
  familyName: string;
  id: string;
  isLogInPatient: boolean;
}

const PatientManager = ({
  env,
  user,
  activeUser,
  setActiveUser,
}: PatientManagerProps) => {
  const [status, setStatus] = useState({ state: '', message: '' });
  const [viewPatient, setViewPatient] = useState('');

  function isUserFollowingOthers() {
    var isFollowing = false;
    if (userRolesFollowing.includes(user.role)) {
      isFollowing = true;
    }

    if (user.role === 'patient' && user.following) {
      isFollowing = true;
    }
    return isFollowing;
  }
  //I'd rather not do it this way, but apparently I can't overload functions so, this is what's happening for now
  function makeMeAnAvailablePatient_User(patient: User) {
    var myPatient = {
      givenName: patient.givenName,
      familyName: patient.familyName,
      id: patient.id,
      isLogInPatient: true,
    };

    return myPatient;
  }

  function makeMeAnAvailablePatient_Followed(patient: Followed) {
    var myPatient = {
      givenName: patient.givenName,
      familyName: patient.familyName,
      id: patient.id,
      isLogInPatient: false,
    };

    return myPatient;
  }

  function buildListOfPatients() {
    let myUsers = [makeMeAnAvailablePatient_User(user)];
    if (isUserFollowingOthers()) {
      //build the list of available patients
      user?.following?.map((patient: Followed) =>
        myUsers.push(makeMeAnAvailablePatient_Followed(patient))
      );
    }
    return myUsers;
  }

  function togglePatient(id: string) {
    let pat = getPatientById(id);

    getUserById(env as keyof TLD, id)
      .then((res) => setActiveUser(res))
      .then(() => {
        setStatus({
          state: 'success',
          message: `Now showing info for ${pat?.givenName} ${pat?.familyName}`,
        });
      })
      .catch((err) => {
        setStatus({
          state: 'error',
          message: err?.elements
            ?.map((e: { id: string; field: string }) => e.id)
            .join('\n'),
        });
      });
  }

  function getPatientById(id: string) {
    var myList = buildListOfPatients();
    return myList.find((pat: availablePatient) => pat.id === id);
  }

  return (
    <div>
      {(user.role !== 'patient' && user.role !== 'prospect') ||
      (user.role === 'patient' && user.following) ? (
        <Form.Group controlId='patientSelect'>
          <Form.Control
            as='select'
            onChange={(e) => togglePatient(e.target.value)}
            data-testid='patientSelect'
            value={viewPatient}
          >
            <option>Change user</option>
            {buildListOfPatients().map((pat: availablePatient) => {
              return (
                <option key={pat.id} value={pat.id}>
                  {' '}
                  {pat.givenName} {pat.familyName}{' '}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      ) : null}
    </div>
  );
};

export default PatientManager;
