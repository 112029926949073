import React, { Dispatch, SetStateAction } from 'react';
import { User } from '../../types/user';
import keycloakAuth from '../../util/keycloak';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { formatDate } from '../../util/dates';
import PatientManager from './PatientManager';

const loggedInPatientText = 'You are logged in as';
const viewingButNotLoggedinAsText = 'You are now viewing';

interface UserInfoProps {
  env: string;
  user: User;
  activeUser: User | undefined;
  setActiveUser: Dispatch<SetStateAction<any>>;
  displayText?: String;
}

export const UserInfo: React.FunctionComponent<UserInfoProps> = ({
  user,
  displayText,
  setActiveUser,
  activeUser,
  env,
}) => {
  //logic to handle a case where the user doesn't have a created date somehow???
  var createdDate = '';
  if (user?.createdDate) {
    createdDate = user.createdDate.split('T')[0];
  }

  //logic to handle a case where the user doesn't have a group I GUESSS???
  var groupName = '';
  if (user?.group) {
    groupName = user?.group.displayName;
  }

  //if the viewing user and user are the same, use logged in text, if they are different use other text
  if (activeUser && user?.id === activeUser.id) {
    displayText = loggedInPatientText;
  } else {
    displayText = viewingButNotLoggedinAsText;
  }

  return (
    <div>
      {activeUser ? (
        <>
          <Card>
            <Card.Header>
              {displayText}{' '}
              <b>
                {activeUser.givenName} {activeUser.familyName}
              </b>
              {} in the <code>{env}</code> environment
            </Card.Header>

            <Card.Body>
              <Button
                onClick={keycloakAuth.logout}
                variant='info'
                size='sm'
                style={{ width: '100%' }}
              >
                Logout
              </Button>
              <br />
              <br />
              <small>
                <PatientManager
                  env={env}
                  user={user}
                  activeUser={activeUser}
                  setActiveUser={setActiveUser}
                />
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <strong>UID</strong>: {activeUser.id}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Email</strong>: {activeUser.email}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Date created</strong>: {createdDate}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Timezone</strong>: {activeUser.timeZone}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Role</strong>: {activeUser.role}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>DOB</strong>: {activeUser.birthDate}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Disease</strong>: {activeUser.disease}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Group</strong>: {groupName}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <b>Medications:</b>
                  </ListGroup.Item>
                  {activeUser.plan?.medications?.map((med) => (
                    <ListGroup.Item key={med.medication.id}>
                      {med.medication.name}
                      {med.sensors?.map((sensor) => (
                        <p key={sensor.mac}>
                          {sensor.model} ({sensor.mac})<br />
                          First sync:{' '}
                          {sensor?.firstSyncDate
                            ? formatDate(sensor.firstSyncDate)
                            : null}
                          <br />
                          Last sync:{' '}
                          {sensor?.lastSyncDate
                            ? formatDate(sensor?.lastSyncDate)
                            : null}
                        </p>
                      ))}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </small>
            </Card.Body>
          </Card>
        </>
      ) : (
        <div>You are not logged in.</div>
      )}
      <br />
    </div>
  );
};

export default UserInfo;
