import React, { useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { freeSensor } from '../../../api/test-hooks';
import { statusFromResponseCode } from '../../../util/feedback';
import Feedback from '../../Feedback/Feedback';

interface FreeSensorProps {
  env: string;
}

const FreeSensor = ({ env }: FreeSensorProps) => {
  const [sensorMac, setSensorMac] = useState('');
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading' });


    if (!sensorMac) {
      setShowAlert(true);
      return setStatus({
        state: 'error',
        message: 'You must enter a MAC address in order to free up a sensor',
      });
    } else {
      let fullMac = `F8:FE:5C:${sensorMac.toUpperCase()}`;

      var successMessage = `Sensor ${sensorMac} is now free and available for association with a new account.`;
      var failMessage = `Unable to free sensor ${sensorMac} from its current owner's account.`;

      freeSensor({
        env,
        macAddress: fullMac,
      })
        .then((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
        })
        .catch((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
        });
        setShowAlert(true);

    }
  };

  return (
    <Card body className='border-light'>
      <p>
        <em>
          If you are reading this, you might have just attempted to add a sensor
          to a test user's plan only to find that the MAC address already
          belongs to somebody else! Fortunately for you, you can make this
          sensor available for use again via the following form:
        </em>
      </p>
      <Form onSubmit={handleSubmit}>
        <Form.Label htmlFor='sensorMac'>
          <b>Enter the MAC address of an unavailable sensor</b>
        </Form.Label>
        <p>
          <em>
            Note:
            <br />
            Please enter MAC addresses exactly as they appear on our sensors:
            A1:B2:C3.
            <br />
            Only letters A-F and numbers from 0-9 are allowed
          </em>
        </p>
        <Row>
          <Col xs='auto'>
            <Form.Group controlId='sensorMac'>
              <InputGroup>
                <InputGroup.Text>F8:FE:5C:</InputGroup.Text>
                <Form.Control
                  onChange={(e) => setSensorMac(e.target.value)}
                  value={sensorMac.toUpperCase()}
                  pattern='^[0-9a-fA-FoO]{2}[ :-]?[0-9a-fA-FoO]{2}[ :-]?[0-9a-fA-FoO]{2}$'
                  type='text'
                  className='col-4'
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Button type='submit'>Free Sensor</Button>
          </Col>
        </Row>
      </Form>
      <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
    </Card>
  );
};

export default FreeSensor;
