import { getApi4Headers, getApiUrl } from '../util/request';
import { TLD } from '../types/tld';

// api 3

export function getGroupConfig(id: string = 'ph-US', env: string) {
  const apiUrl = getApiUrl(env as keyof TLD);
  return fetch(`${apiUrl}/groups/${id}/config`, {
    method: 'GET',
    headers: {
      'x-ph-api-version': '3.92.0',
    },
  }).then((res) => res.json());
}

// formats the path to the consent
// api sends full path from the groupConfig,
// but we need to send in the relative path with ${locale}.html added
// for user creation request in API 3
export function getUserAgreementByGroup(groupId: string, env: string) {
  const TERMS_ROOT = 'https://s3-us-west-2.amazonaws.com/ph-legal';
  return getGroupConfig(groupId, env).then((res) => {
    if (res.defaultTermsAndConditions) {
      return `${res.defaultTermsAndConditions.replace(
        `${TERMS_ROOT}/`,
        ''
      )}en-US.html`;
    }
    return 'termsAndConditions/defaultNonCE/US/en-US.html';
  });
}

// api 4

export function getGroupMeds(groupId: string, env: string) {
  const apiUrl = getApiUrl(env as keyof TLD);
  return fetch(`${apiUrl}/groups/${groupId}/medications`, {
    method: 'GET',
    headers: getApi4Headers(),
    credentials: 'include',
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      if (res.elements) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
}
