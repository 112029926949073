import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { ageSensor } from '../../../api/test-hooks';
import { User } from '../../../types/user';
import { Medication, Sensor } from '../../../types/plan';
import { timeFormatter } from '../../../util/timeZoneConverter';
import { getOneMonthAgoAsString } from '../../../util/dates';
import Feedback from '../../Feedback/Feedback';
import { statusFromResponseCode } from '../../../util/feedback';

interface AgeSensorProps {
  env: string;
  user: User | undefined;
}

const AgeSensor = ({ env, user }: AgeSensorProps) => {
  const [dateCreated, setDateCreated] = useState(`${getOneMonthAgoAsString()}`);
  const [sensorMac, setSensorMac] = useState('');
  const [timeCreated, setTimeCreated] = useState('00:00');
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading' });
    if (user) {
      if (!user.id || !dateCreated || !sensorMac || !timeCreated) {
        setShowAlert(true);
        return setStatus({
          state: 'error',
          message: 'enter a UID, date, and time.',
        });
      }
        

      if (user.id && dateCreated && sensorMac) {
        var successMessage = `Aged ${sensorMac} for ${user.givenName} ${user.familyName} in ${env} to ${dateCreated}.`;
        var failMessage =  `Unable to age ${sensorMac} for ${user.givenName} ${user.familyName} in ${env}.`;
        ageSensor({
          env,
          uid: user.id,
          dateC: timeFormatter(user.timeZone, dateCreated, timeCreated),
          macAddress: sensorMac,
        })
          .then((res) => {
            setStatus({
              state: res.state,
              message: res.message + ' ' + successMessage,
            });
          })
          .catch((res) => {
            setStatus({
              state: res.state,
              message: res.message + ' ' + failMessage,
            });
          });
         setShowAlert(true);
      }

    }
  }

  return (
    <Card body className='border-light'>
      <Form onSubmit={handleSubmit}>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Form.Group controlId='sensorMac'>
              <Form.Label>Select Sensor</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setSensorMac(e.target.value)}
                value={sensorMac}
              >
                <option value="">Select a sensor to age</option>
                {user?.plan.medications?.map((med: Medication) => {
                  return med.sensors.map((sensor: Sensor) => (
                    <option key={sensor.mac}> {sensor.mac} </option>
                  ));
                })}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Form.Group controlId='sensorDateCreated'>
              <Form.Label>Enter Age Sensor Date</Form.Label>
              <Form.Control
                type='date'
                name='sensorDateCreated'
                onChange={(e) => setDateCreated(e.target.value)}
                value={dateCreated}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId='sensorDateCreated'>
              <Form.Label>Enter Age Sensor Time</Form.Label>
              <Form.Control
                type='time'
                name='sensorTimeCreated'
                onChange={(e) => setTimeCreated(e.target.value)}
                value={timeCreated}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ alignItems: 'center' }}>
          <Col>
            <Button type='submit'>Age Sensor</Button>
          </Col>
        </Row>
      </Form>
      <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
    </Card>
  );
};

export default AgeSensor;
