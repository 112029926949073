import { getApi4Headers, getApiUrl, request3x, request4x } from '../util/request';
import { TLD } from '../types/tld';

// 3.x endpoints

export function createUser(env: keyof TLD = 'dev', data: any) {
  const apiURL = getApiUrl(env);

  return fetch(`${apiURL}/users/`, {
    method: 'POST',
    headers: {
      'x-ph-api-version': '3.93.0',
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((res) => {
      // error messages are returned in an "elements" array
      if (res.elements) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
}

export function signOut(env: keyof TLD = 'dev') {
  const apiURL = getApiUrl(env);
  return fetch(`${apiURL}/users/signout/`, {
    method: 'POST',
    headers: {
      'x-ph-api-version': '3.93.0',
      'Content-Type': 'application/json; charset=utf-8',
    },
    credentials: 'include',
  });
}

export function sendAppHeartbeat(
  env: keyof TLD = 'dev',
  userId: string,
  heartbeatTime: string
) {
  const apiURL = getApiUrl(env);
  const url = `${apiURL}/heartbeats`;
  return request3x('POST', url, {
    date: heartbeatTime,
    transmitDevice: {
      appVersion: '9.9.99',
      bluetoothOn: true,
      model: 'Positronic',
      platform: 'android',
      platformVersion: '9.9.99',
      pushOn: true,
    },
    userId: userId,
  });
}

// 4.x endpoints

export function getUser(env: keyof TLD) {
  const apiURL = getApiUrl(env);
  return fetch(`${apiURL}/users/me`, {
    method: 'GET',
    headers: getApi4Headers(),
    credentials: 'include',
  }).then((res) => res.json());
}

export function getUserById(env: keyof TLD, userId: string) {
  const apiURL = getApiUrl(env);
  return fetch(`${apiURL}/users/${userId}`, {
    method: 'GET',
    headers: getApi4Headers(),
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((res) => {
      // error messages are returned in an "elements" array
      if (res.elements) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
}

export function addMedToPlan(env: string, userId: string, data: object) {
  const apiURL = getApiUrl(env as keyof TLD);
  const request = new Request(`${apiURL}/users/${userId}/plan/medication`, {
    method: 'POST',
    headers: getApi4Headers(),
    credentials: 'include',
    body: JSON.stringify(data),
  });
  return fetch(request)
    .then((res) => {
      if (res.ok) {
        return { success: true };
      }
      return res.json();
    })
    .then((res) => {
      if (res?.elements) {
        return Promise.reject(res);
      } else {
        return res;
      }
    });
}

export function addMedToPlan2(env: string, userId: string, data: object) {
  const apiURL = getApiUrl(env as keyof TLD);
  var requestUrl = `${apiURL}/users/${userId}/plan/medication`;

  return request4x('POST', requestUrl, data ).then((res) => {
    return res;
  });
}


export function updateUserTimezone(
  env: keyof TLD = 'dev',
  userId: string,
  tzone: string
) {
  var data = { timeZone: tzone };
  const apiURL = getApiUrl(env);
  var url = `${apiURL}/users/${userId}`

  return request4x('PATCH', url, data ).then((res) => {
    return res;
  });
}