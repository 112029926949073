import { getTimezoneOffset } from 'date-fns-tz';
import { getTimeZones } from "@vvo/tzdb";


//takes in a string describing the user's timezone, returns the offset from UTC time in HOURS for the given timezone.
export function getUTCOffset(timezoneString: string) {
  var offset = 0;
  offset = getTimezoneOffset(timezoneString) / (1000 * 60 * 60);
  var strOffset = offset.toString().concat(':00');
  if(strOffset.length == 5){
    strOffset = strOffset.substring(0,1)+'0'+strOffset.substring(1);
  }
  return strOffset;
}

//convert user selected date to UTC for the API, using the timezone listed on the user's user model.
export function timeFormatter(
  timezoneString: string,
  selectedDate: string,
  selectedTime: string
) {
  var tzOffset = getUTCOffset(timezoneString);
  var datetimestring = selectedDate.concat('T').concat(selectedTime).concat(':00.000').concat(tzOffset);
  return datetimestring;
}

export interface TimeZone {
  name: string,
  alternativeName: string,
  group: string[],
  continentCode: string,
  continentName: string,
  countryName: string,
  countryCode: string,
  mainCities: string[],
  rawOffsetInMinutes: number,
  abbreviation: string,
  rawFormat: string
}

export function timeZoneList() {
  var tzList: TimeZone[];
  tzList = getTimeZones();
  return tzList;
}

