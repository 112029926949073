import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import keycloakAuth from '../../util/keycloak';
import CreateUser from '../CreateUser/CreateUser';
import NewUserInfo from '../CreateUser/NewUserInfo/NewUserInfo';
import { API3User } from '../../types/api3/user';

export default function Login({
  env,
  setEnv,
}: {
  env: string;
  setEnv: (e: string) => void;
}) {
  const [user, setUser] = useState<API3User | undefined>(undefined);
  const [patient, setPatient] = useState<API3User | undefined>(undefined);
  const [createUser, setCreateUser] = useState(false);

  function changeEnv(newEnv: string) {
    setEnv(newEnv);
    localStorage.setItem('propellerEnv', newEnv);

    // preemtive logout of keycloak in the current env first
    keycloakAuth.init(env).then(() => {
      keycloakAuth.logout();
    });
  }

  return (
    <>
      {createUser ? null : (
        <>
          <Card>
            <Card.Header>
              <Card.Title>1. Pick an environment</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Text>
                  Be sure the user and/or group you want to use exists in the
                  environment you select.
                </Form.Text>
                <Form.Text>
                  Click{' '}
                  <a
                    href='https://discourse.propellerhealth.com/t/how-do-i-change-the-server-the-app-uses/82'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    here{' '}
                  </a>
                  for instructions on how to change the environment of the
                  Propeller app on your phone.
                </Form.Text>
                <br />
                <Form.Control
                  as='select'
                  value={env}
                  onChange={(e) => changeEnv(e.target.value)}
                >
                  <option value='dev'>Dev</option>
                  <option value='test'>Test</option>
                  <option value='demo'>Demo</option>
                  <option value='sndbx'>Sandbox</option>
                </Form.Control>
              </Form.Group>
            </Card.Body>
          </Card>
          <br />
          <Card>
            <Card.Header>
              <Card.Title>
                2. Login as an existing user or create a new user
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Button variant='info' size='lg' onClick={keycloakAuth.login}>
                Login
              </Button>
              &nbsp;
              <Button
                variant='info'
                size='lg'
                onClick={() => setCreateUser(true)}
              >
                Create a user
              </Button>
            </Card.Body>
          </Card>
        </>
      )}
      {createUser && !user ? (
        <CreateUser env={env} setUser={setUser} setPatient={setPatient} />
      ) : null}
      {user ? <NewUserInfo user={user} patient={patient} /> : null}
    </>
  );
}
