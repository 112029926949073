import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

interface UsageProps {
  index: number;
  unitDoses: number | string;
  hour: number | string;
  minute: number | string;
  setUsages: ({
    index,
    unitDoses,
    hour,
    minute,
  }: {
    index: number;
    unitDoses: number | string;
    hour: number | string;
    minute: number | string;
  }) => void;
}

export default function UsageItem(usage: UsageProps) {
  const [unitDoses, setUnitDoses] = useState(usage.unitDoses || 1);
  const [hour, setHour] = useState(usage.hour);
  const [minute, setMinute] = useState(usage.minute);
  const { index, setUsages } = usage;

  function handleDoseChange(doses: number | string) {
    setUnitDoses(doses);
    setUsages({ index, unitDoses: doses, hour, minute });
  }

  function handleTimeChange(val: any) {
    const time = val.split(':');
    setHour(time[0]);
    setMinute(time[1]);
    setUsages({ index, unitDoses, hour: time[0], minute: time[1] });
  }

  return (
    <Form.Row>
      <Col xs={6}>
        <Form.Group controlId='unitDoses'>
          <Form.Label>Doses</Form.Label>
          <Form.Control
            as='select'
            value={unitDoses}
            onChange={(e) => handleDoseChange(e.target.value)}
          >
            {[...new Array(16).keys()].map((i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col xs={6}>
        <Form.Group controlId='time'>
          <Form.Label>Time</Form.Label>
          <Form.Control
            name='hour'
            type='time'
            value={`${hour}:${minute}`}
            onChange={(e) => handleTimeChange(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Form.Row>
  );
}
