import { request, getApiUrl } from '../util/request';
import { TLD } from '../types/tld';

export function ageAccount({
  env,
  uid,
  dateC,
}: {
  env: string;
  uid: string;
  dateC: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/ageaccount`;
  const date = new Date(dateC).toISOString();
  return request('POST', url, { uid: uid, dateC: date }).then((res) => {
    var status = { state: res.state, message: res.message };
    return status;
  });
}

export function cronJob({
  env,
  uid,
  cronName,
  date,
  tzone,
  group,
  days,
}: {
  env: string;
  uid: string;
  cronName: string;
  date?: string;
  tzone?: string;
  group?: string;
  days?: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/cron`;
  return request('POST', url, {
    uid,
    name: cronName,
    date,
    tzone,
    group,
    days,
  });
}

export function ageSensor({
  env,
  uid,
  dateC,
  macAddress,
}: {
  env: string;
  uid: string;
  dateC: string;
  macAddress: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/agesensor`;
  const date = new Date(dateC).toISOString();
  const transmitDevice = 'Postronic 2.0'; //transmit device cannot be empty, but can be any string
  return request('POST', url, {
    uid: uid,
    dateC: date,
    mac: macAddress,
    transmit_device: transmitDevice,
  });
}

export function freeSensor({
  env,
  macAddress,
}: {
  env: string;
  macAddress: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/freesensor`;
  return request('POST', url, {
    mac: macAddress,
  });
}

export function generateActOrCatCard({
  env,
  uid,
  quiz,
}: {
  env: string;
  uid: string;
  quiz: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/${quiz}card`;
  return request('POST', url, {
    uid: uid,
  });
}

export function generateWelcomeCard({
  env,
  uid,
}: {
  env: string;
  uid: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/welcometopropellercard`;
  return request('POST', url, {
    uid: uid,
  });
}

export function generateKnownTriggersCard({
  env,
  uid,
}: {
  env: string;
  uid: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/knowntriggerscard`;
  return request('POST', url, {
    uid: uid,
  });
}

export function generateRescueInputScheduleCard({
  env,
  uid,
}: {
  env: string;
  uid: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/rescueinputschedulecard`;
  return request('POST', url, {
    uid: uid,
  });
}

// the possible card template names accepted by the generateCard endpoint
export enum GenerateCardTemplateName {
  SET_NEXT_REFILL_REMINDER = 'set_next_refill_reminder',
  HOW_PROPELLER_WORKS = 'how_propeller_works',
  DID_YOU_GET_A_REPEAT_PRESCRIPTION = 'did_you_get_a_repeat_prescription',
  NOVARTIS_ENERZAIR_SENSOR_BATTERY_LOW = 'novartis_enerzair_sensor_battery_low',
  NOVARTIS_ATECTURA_SENSOR_BATTERY_LOW = 'novartis_atectura_sensor_battery_low',
  NOVARTIS_ENERZAIR_MOVE_SENSOR = 'novartis_enerzair_move_sensor',
  NOVARTIS_ATECTURA_MOVE_SENSOR = 'novartis_atectura_move_sensor',
  NPKK_REFILL_MOVE_SENSOR = 'npkk_refill_move_sensor',
}

export function generateCardFromTemplateName({
  env,
  uid,
  templateName,
}: {
  env: string;
  uid: string;
  templateName: GenerateCardTemplateName;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/generateCard`;
  return request('POST', url, {
    uid,
    templateName,
  });
}

export function generateSurveyMonkeyTestCard({
  env,
  uid,
  surveyId,
}: {
  env: string;
  uid: string;
  surveyId: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/generateCard`;
  return request('POST', url, {
    uid,
    templateName: 'surveymonkey_test',
    surveyId,
  });
}

//creates the initial shipment and shipment card for the user
//required fields: macs, status, userID.
//all others will be ignored for now
export function shipPackages({
  env,
  uid,
  mac,
  ship_status,
}: {
  env: string;
  uid: string;
  mac: string;
  ship_status: string;
}) {
  const apiUrl = getApiUrl(env as keyof TLD);
  const url = `${apiUrl}/testhooks/packages`;
  return request('POST', url, {
    //carrierCode: "carrier_code",
    //date: "2021-04-12T09:46:05-05:00",
    //estDelieveryDate: "2021-04-22T09:46:05-05:00",
    macs: [mac],
    //serviceLevel: "usps_priority",
    status: ship_status,
    userId: uid,
  });
}
