import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Header from './components/Header';
import Login from './pages/Login/Login';
import Home from './pages/Home';

import keycloakAuth from './util/keycloak';
import { getUser } from './api/user';
import { User } from './types/user';
import { TLD } from './types/tld';

function App() {
  const [env, setEnv] = useState(
    localStorage?.getItem('propellerEnv') || 'dev'
  );
  const [user, setUser] = useState<undefined | User>(undefined);
  const [status, setStatus] = useState({ state: '', message: '' });

  useEffect(() => {
    keycloakAuth.init(env).then((authenticated: boolean) => {
      if (authenticated && !user) {
        getUser(env as keyof TLD)
          .then((res) => setUser(res))
          .catch((err) => setStatus({ state: 'error', message: err }));
      }
    });
  }, [env]);

  return (
    <Container>
      <Header />
      <div>
        {!user ? (
          <Login env={env} setEnv={setEnv} />
        ) : (
          <Home user={user} env={env} />
        )}
      </div>
      {status.state === 'error' ? (
        <Alert variant='danger'>{`Something went wrong: ${status.message}`}</Alert>
      ) : null}
    </Container>
  );
}

export default App;
