import React from 'react';
import { Accordion, Badge } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { User } from '../../../types/user';
import ActionWrapper from '../../ActionWrapper/ActionWrapper';
import ACTorCATCardGenerator from './ACTorCATCardGenerator';
import KnownTriggersCardGenerator from './KnownTriggersCardGenerator';
import RescueInputScheduleCardGenerator from './RescueInputScheduleCardGenerator';
import WelcomeCardGenerator from './WelcomeCardGenerator';
import SurveyMonkeyTestCardGenerator from './SurveyMonkeyTestCardGenerator';
import CardTemplateNameCardGenerator from './CardTemplateNameCardGenerator';
import { GenerateCardTemplateName } from '../../../api/test-hooks';

interface CardToolsProps {
  env: string;
  user: User | undefined;
}

// TODO: handle error and success messages
const CardTools: React.FunctionComponent<CardToolsProps> = ({ env, user }) => {
  return (
    <Card body className='sensorTools border-light'>
      <h3>Card Tools</h3>
      <p>
        <em>Various actions enabling you to generate and manipulate cards.</em>
      </p>
      <Accordion>
        <ActionWrapper
          title='Card Generators'
          description='what does a card generator do?'
        >
          <div>
            <br />
            <p>
              The card generators allow testers to force a particular card to
              appear in a user's feed even if that patient is not scheduled to
              receive that card at the moment. This allows users to test new
              card functionality without having to wait for a specific time or
              get the test account into a specific state, when only the card
              itself needs to be tested.{' '}
            </p>
            <p>
              Positronic's cron actions are primarily designed for QA testing
              purposes. If you ended up here because you want to test whether or
              not a card is triggered appropriately, we suggest trying the 'cron
              actions' tab instead{' '}
            </p>
          </div>
        </ActionWrapper>
        <ActionWrapper
          title={'Welcome Card'}
          description='Generates a welcome card in the mobile app'
        >
          <WelcomeCardGenerator env={env} user={user} />
        </ActionWrapper>
        <ActionWrapper
          title={'ACT Card'}
          description='Generates a ACT card in the mobile app'
        >
          <ACTorCATCardGenerator env={env} user={user} condition={'asthma'} />
        </ActionWrapper>
        <ActionWrapper
          title={'CAT Card'}
          description='Generates a CAT card in the mobile app'
        >
          <ACTorCATCardGenerator env={env} user={user} condition={'copd'} />
        </ActionWrapper>
        <ActionWrapper
          title={'Known Triggers Card'}
          description='Generates a Known Triggers card in the mobile app'
        >
          <KnownTriggersCardGenerator env={env} user={user} />
        </ActionWrapper>
        <ActionWrapper
          title={'Rescue Input Schedule Card'}
          description='Generates a Rescue Input Schedule card in the mobile app'
        >
          <RescueInputScheduleCardGenerator env={env} user={user} />
        </ActionWrapper>
        <ActionWrapper
          title={'SurveyMonkey Survey Test Card'}
          description='Generates a placeholder card in the mobile app that points to the specified SurveyMonkey survey'
        >
          <SurveyMonkeyTestCardGenerator env={env} user={user} />
        </ActionWrapper>
        <ActionWrapper
          title='Novartis Co-pack Set Next Refill Reminder Card'
          description='Card to ask users to set a one-time refill reminder for their medication'>
            <CardTemplateNameCardGenerator env={env} user={user} 
                templateName={GenerateCardTemplateName.SET_NEXT_REFILL_REMINDER}
                actionText='Generate NVS Next Refill Reminder Card'/>
          </ActionWrapper>
        <ActionWrapper
          title='Novartis Co-pack How Propeller Works Card'
          description='Card to welcome NVS Co-Pack users to Propeller with information about Propeller'>
            <CardTemplateNameCardGenerator env={env} user={user} 
                templateName={GenerateCardTemplateName.HOW_PROPELLER_WORKS}
                description='Generates an introductory card about how Propeller works when they are a Novartis Co-Pack user.'
                actionText='Generate NVS How Propeller Works Card'/>
          </ActionWrapper>
          <ActionWrapper
          title='Novartis Co-pack Have You Got a Repeat Prescription? Card'
          description='Card to help guide users who have a repeat prescription move over their existing sensor to their new prescribed medication'>
            <CardTemplateNameCardGenerator env={env} user={user} 
                templateName={GenerateCardTemplateName.DID_YOU_GET_A_REPEAT_PRESCRIPTION}
                actionText='Generate NVS Have You Got a Repeat Prescription? Card'/>
          </ActionWrapper>
          <ActionWrapper
          title='Novartis Co-pack Enerzair Low Battery Card'
          description='Card letting users know that the battery for their sensor for their Enerzair inhaler is low and how to set up a new sensor'>
            <CardTemplateNameCardGenerator env={env} user={user} 
                templateName={GenerateCardTemplateName.NOVARTIS_ENERZAIR_SENSOR_BATTERY_LOW}
                actionText='Generate NVS Enerzair Low Battery Card'/>
          </ActionWrapper>
          <ActionWrapper
          title='Novartis Co-pack Atectura Low Battery Card'
          description='Card letting users know that the battery for their sensor for their Atectura inhaler is low and how to set up a new sensor'>
            <CardTemplateNameCardGenerator env={env} user={user} 
                templateName={GenerateCardTemplateName.NOVARTIS_ATECTURA_SENSOR_BATTERY_LOW}
                actionText='Generate NVS Atectura Low Battery Card'/>
          </ActionWrapper>
          <ActionWrapper
          title='Novartis Co-pack Enerzair Move Sensor Card'
          description='Card to let users know that they must throw away their Enerzair medication after 30 days and how to move their sensor to their new medication'>
            <CardTemplateNameCardGenerator env={env} user={user} 
                templateName={GenerateCardTemplateName.NOVARTIS_ENERZAIR_MOVE_SENSOR}
                actionText='Generate NVS Enerzair Move Sensor Card'/>
          </ActionWrapper>
          <ActionWrapper
          title='Novartis Co-pack Atectura Move Sensor Card'
          description='Card to let users know that they must throw away their Atectura medication after 30 days and how to move their sensor to their new medication'>
            <CardTemplateNameCardGenerator env={env} user={user} 
                templateName={GenerateCardTemplateName.NOVARTIS_ATECTURA_MOVE_SENSOR}
                actionText='Generate NVS Atectura Move Sensor Card'/>
          </ActionWrapper>
          <ActionWrapper
          title='NPKK Refill Move Sensor Card'
          description='Card to let users know how to move their sensor when they get a refill prescription'>
            <CardTemplateNameCardGenerator env={env} user={user} 
                templateName={GenerateCardTemplateName.NPKK_REFILL_MOVE_SENSOR}
                actionText='Generate NPKK Refill Move Sensor Card'/>
          </ActionWrapper>
      </Accordion>
    </Card>
  );
};

export default CardTools;
