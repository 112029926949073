import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { User } from '../../../types/user';
import { generateKnownTriggersCard } from '../../../api/test-hooks';
import { isAsthmaPatient } from '../../../util/ComponentEligibiltyUtils';
import Feedback from '../../Feedback/Feedback';

//This card should be shown for the following users
//  - patients with asthma as their disease
//Additional relevent info from the SRS
//  - The Known Triggers Card will be shown to users with asthma when they first use the app after achieving first sync. [SRS SAVE-1294.01]
//  - The card will take them to a screen where they can select the known factors that trigger asthma symptoms. [SRS SAVE-1294.02]

interface KnownTriggersCardGeneratorProps {
  env: string;
  user: User | undefined;
}

const KnownTriggersCardGenerator = ({
  env,
  user,
}: KnownTriggersCardGeneratorProps) => {
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  function knownTriggersCardGenerator(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading ' });

    if (user?.id) {
      var successMessage = `Generated Known Triggers Card for ${user.givenName} ${user.familyName} in ${env}`;
      generateKnownTriggersCard({ env, uid: user.id })
        .then((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
        })
        .catch(() => {
          setStatus({ state: 'error', message: 'Something went wrong' });
        });
      setShowAlert(true);
    }
  }

  return (
    <tr>
      <td>
        Generates a card inviting the patient to save their {user?.disease}{' '}
        triggers in the Propeller app.
        <br />
        <br />
        <Form onSubmit={(event) => knownTriggersCardGenerator(event)}>
          {isAsthmaPatient(user) ? (
            ''
          ) : (
            <p>
              <b>
                This patient doesn't fulfill the requirements for this card.
                This card is only available to asthma patients.
              </b>
            </p>
          )}
          <Button
            type='submit'
            disabled={!isAsthmaPatient(user)}
            id='knownTriggersCard'
          >
            Generate Known Triggers Card
          </Button>
        </Form>
        <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      </td>
    </tr>
  );
};

export default KnownTriggersCardGenerator;
