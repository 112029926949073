import { createDOB } from '../../util/dates';

const faker = require('faker');

export const fakeGivenName = faker.name.firstName();
export const fakeFamilyName = faker.name.lastName();
export const fakeEmail = `${fakeGivenName}.${fakeFamilyName}@example.com`;

function mongoObjectId() {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
}

export const fakeUser = {
  email: fakeEmail,
  id: mongoObjectId(),
  mailingAddress: {
    street: faker.address.streetAddress(),
    stateOrRegion: faker.address.state(),
    city: faker.address.city(),
    country: 'US',
    postalCode: faker.address.zipCode(),
  },
  medicalIds: [{ key: 'MedicalId', value: '1234' }],
  password: 'Password1',
  timeZone: 'America/Chicago',
};

export function handleAge(userType: string) {
  switch (userType) {
    case 'adult':
    // falls through
    case 'caregiver':
      return createDOB(new Date(), 30);
    case 'child':
      return createDOB(new Date(), 10);
    default:
      return createDOB(new Date(), 30);
  }
}

interface UserProps {
  caregiverId?: string;
  caregiverFirst?: string;
  caregiverLast?: string;
  caregiverEmail?: string;
  disease: string;
  groupId: string;
  userType: string;
  givenName: string;
  familyName: string;
  email: string;
}

// function to find the program?
export function createUserData({
  caregiverId,
  caregiverFirst,
  caregiverLast,
  caregiverEmail,
  disease,
  groupId,
  userType,
  givenName,
  familyName,
  email,
}: UserProps) {
  const role = userType === 'caregiver' ? 'caregiver' : 'patient';

  let userObject;
  if (role === 'caregiver') {
    userObject = {
      id: mongoObjectId(),
      givenName: caregiverFirst,
      familyName: caregiverLast,
      email: caregiverEmail,
      group: { name: groupId },
      role,
    };
  } else {
    userObject = {
      act: '20',
      cat: '30',
      id: mongoObjectId(),
      gender: 'female',
      caregiverId,
      birthDate: handleAge(userType),
      givenName,
      familyName,
      email,
      disease,
      group: { name: groupId },
      role,
    };
  }

  return { ...fakeUser, ...userObject };
}
