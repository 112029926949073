import React from 'react';
import CardTools from '../CardActions/CardTools/CardTools';
import CronTools from '../CronActions/CronTools';
import SensorTools from '../SensorActions/SensorTools/SensorTools';
import UserTools from '../UserActions/UserTools/UserTools';
import Utilities from '../Utilities/Utilities';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FAQs from '../FAQs/FAQs';
import { User } from '../../types/user';

interface ActionTabsProps {
  env: string;
  user: User | undefined;
}

export const ActionTabs = ({ env, user }: ActionTabsProps) => (
  <Tabs defaultActiveKey='user'>
    <Tab eventKey='user' title='user actions'>
      <UserTools env={env} user={user} />
    </Tab>
    <Tab eventKey='sensor' title='sensor actions'>
      <SensorTools env={env} user={user} />
    </Tab>
    <Tab eventKey='cards' title='card actions'>
      <CardTools env={env} user={user} />
    </Tab>
    <Tab eventKey='cron' title='cron actions'>
      <CronTools env={env} user={user} />
    </Tab>
    <Tab eventKey='faq' title='FAQs'>
      <FAQs />
    </Tab>
    <Tab eventKey='utilities' title='Utilities'>
      <Utilities env={env} />
    </Tab>
  </Tabs>
);
