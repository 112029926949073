import React from 'react';
import ActionWrapper from '../ActionWrapper/ActionWrapper';
import { Card, Accordion } from 'react-bootstrap';
import FreeSensor from './FreeSensor/FreeSensor';

interface UtilitiesProps {
  env: string;
}

const Utilities: React.FunctionComponent<UtilitiesProps> = ({ env }) => {
  return (
    <Card body className='utilities border-light'>
      <h3>Utilities</h3>
      <p>
        <em>
          A collection of standalone utilities that you may find useful
          in your user creating and updating escapades.
        </em>
      </p>
      <Accordion>
        <ActionWrapper
          title='Free Sensor'
          description='Removes a sensor from a user by MAC address'
        >
          <Card body className='border-light'>
            <FreeSensor
              env={env} 
            />
          </Card>
        </ActionWrapper>
      </Accordion>
    </Card>
  );
}

export default Utilities;