import React from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { API3User } from '../../../types/api3/user';
import keycloakAuth from '../../../util/keycloak';

interface NewUserInfoProps {
  user: API3User;
  patient?: API3User; // if the user is a caregiver
}

export default function NewUserInfo({ user, patient }: NewUserInfoProps) {
  const patientId = patient ? patient.id : user.id;
  const patientEmail = patient ? patient.email : user.email;
  const patientName = patient
    ? `${patient.givenName} ${patient.familyName}`
    : `${user.givenName} ${user.familyName}`;

  return (
    <section>
      <p>Your new user is:</p>
      <ListGroup>
        {user.role === 'caregiver' ? (
          <ListGroup.Item>
            <strong>Caregiver name</strong>: {user.givenName} {user.familyName}{' '}
          </ListGroup.Item>
        ) : null}
        <ListGroup.Item>
          <strong>Patient name</strong>: {patientName}
        </ListGroup.Item>
        <ListGroup.Item>
          <strong>Patient id</strong>: {patientId}
        </ListGroup.Item>
        {user.role === 'caregiver' ? (
          <ListGroup.Item>
            <strong>Caregiver id</strong>: {user.id}
          </ListGroup.Item>
        ) : null}
        <ListGroup.Item>
          <strong>Patient email</strong>: {patientEmail}
        </ListGroup.Item>
        {user.role === 'caregiver' ? (
          <ListGroup.Item>
            <strong>Caregiver email</strong>: {user.email}
          </ListGroup.Item>
        ) : null}
        <ListGroup.Item>
          <strong>Patient password</strong>: Password1
        </ListGroup.Item>
        {user.role === 'caregiver' ? (
          <ListGroup.Item>
            <strong>Caregiver password</strong>: Password1
          </ListGroup.Item>
        ) : null}
      </ListGroup>
      <br />
      <h5>One more step:</h5>
      To add medications to the patient's plan, copy the patient's email
      address,{' '}
      <Button variant='link' onClick={keycloakAuth.login}>
        login
      </Button>{' '}
      , then click "Add a medication" when you are redirected to the home page.
    </section>
  );
}
