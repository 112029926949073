import React from 'react';
import { User } from '../../types/user';

export const ActOrCatDescription = ({ user }: { user: User }) => {
  const quizName = user?.disease === 'asthma' ? 'ACT' : 'CAT';

  return (
    <>
      <p>
        Sends a user the {quizName} Cards and notifications. If a user has
        taken the {quizName} in last 30 days, this cron task does not force
        send the {quizName} again.
      </p>
      <p>Preconditions:</p>
      <ul>
        <li>The patient must have {user?.disease} as their condition.</li>
        <li>
          The patient cannot not have taken the {quizName} in the past 30
          days.
        </li>
      </ul>
    </>
  );
}

export const RescueInputScheduleDescription = () => {
  return (
    <>
      <p>
        This cron generates a Rescue Input Card in the selected
        patient’s card feed with text asking them how many times 
        they have used their medication this week. The patient must 
        have a rescue medication associated with their account and have{' '}
        <strong>no</strong> sensor associated with that rescue
        medication.
      </p>
      <p>
        The Rescue Input Card allows the user to input rescue uses
        at certain times throughout the week.
      </p>
      <p>
        To run this cron and get the expected results, please enter the date of the
        most recent Saturday with 5:00 PM as the time in the following form.
      </p>
      <p>How to check that it worked:</p>
      <ul>
        <li>
          Before running the cron, log into the patient’s account on a mobile device.
        </li>
        <li>
          You should receive a notification asking you if you took your rescue medication this week.
        </li>
        <li>
          Tapping the notification should take you to the corresponding card in the patient's card feed.
        </li>
      </ul>
      <p>
        Additionally, if the patient is logged in to the account on
        a mobile device at the time the card is generated, they will
        also receive a push notification directing them to open the
        app and enter their rescue usages.
      </p>
    </>
  );
}

export const RescueInputScheduleUpdateDescription = () => {
  return (
    <>
      <p>
        This cron updates a Rescue Input Card in the selected
        patient’s card feed with text asking them how many times 
        they used their medication <i>last</i> week rather than <i>this</i>
        week. The patient must have a rescue medication associated with 
        their account and have <b>no</b> sensor associated with 
        that rescue medication.
      </p>
      <p>
        The Rescue Input Card allows the user to input rescue uses
        at certain times over the last week.
      </p>
      <p>
        To run this cron and get the expected results, please enter the date of the
        most recent Sunday with 5:00 PM as the time in the following form.
      </p>
      <p>How to check that it worked:</p>
      <ul>
        <li>          
          Before running the cron, log into the patient’s account on a mobile device.
        </li>
        <li>
          The updated card should appear in the patient’s main card feed 
          about halfway down the feed, and should prompt the user  
          to enter <em>last</em> week's medicaion usage.
        </li>
      </ul>
    </>
  );
}

export const QuietSensorDescription = () => {
  return (
    <>
      <p>
        If a user has a sensor that has not synced in 48 hours, this
        cron task sends a notification.
      </p>
      <p>Testing Process:</p>
      <ul>
        <li>Age the account 1 week.</li>
        <li>Age one of the patient's sensors 48 hours.</li>
        <li>Run the Quiet Sensor cron task.</li>
      </ul>
    </>
  );
}