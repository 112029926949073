import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { User } from '../../../types/user';
import { generateWelcomeCard } from '../../../api/test-hooks';
import { isNonClicicalUser } from '../../../util/ComponentEligibiltyUtils';
import Feedback from '../../Feedback/Feedback';

//this action should show for the following propeller users, when those users are listed as "active"
//  - single patients
//  - caregivers
//  - control patients
//
//it should NOT show for providers or admins
//I don't think it should show for prospects, but I am uncertain tbd.

const allowedUserTypes = ['caregiver', 'control', 'patient'];

interface WelcomeCardGeneratorProps {
  env: string;
  user: User | undefined;
}

const WelcomeCardGenerator = ({ env, user }: WelcomeCardGeneratorProps) => {
  const [status, setStatus] = useState({ state: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);

  function welcomeCardGenerator(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setStatus({ state: 'loading', message: 'loading ' });

    if (user?.id) {
      var successMessage = `Generated Welcome Card for ${user.givenName} ${user.familyName} in ${env}`;
      generateWelcomeCard({ env, uid: user.id })
        .then((res) => {
          setStatus({
            state: res.state,
            message: res.message + ' ' + successMessage,
          });
        })
        .catch(() => {
          setStatus({ state: 'error', message: 'Something went wrong' });
        });
      setShowAlert(true);
    }
  }

  return (
    <tr>
      <td>
        Generates an introductory welcome card. This is the first card that most
        patients will see in their feed when they sign into the mobile app for
        the first time.
        <br />
        <br />
        <Form onSubmit={(event) => welcomeCardGenerator(event)}>
          {isNonClicicalUser(user) ? (
            ''
          ) : (
            <p>
              This user doesn't fulfill the requirements for this card. This
              card is not available to providers.{' '}
            </p>
          )}
          <Button
            type='submit'
            disabled={!isNonClicicalUser(user)}
            id='welcomeCard'
          >
            Generate Welcome Card
          </Button>
        </Form>
        <Feedback
          status={status}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      </td>
    </tr>
  );
};
export default WelcomeCardGenerator;
